import {isNumeric} from 'library/helpers';
import {time} from 'library/helpers';

class Validator {

    constructor(data){

        if(data !== undefined){

            this.setData(data);
        }

        this.errors = new Map();
        
        return this;
    }

    // Add custom rule

    add(rule, callback){

        // Save name of the rule for later use in setError method

        this[rule] = (...params) => {

            return callback(this, rule, ...params);
        };
    }

    // Helper create new custom rule

    rule(callback){

        this.values.forEach((v, i) => {

            if(v !== ''){

                // Save index of the value for later use in setError method

                this.index = i;

                callback(v);
            }
        });

        return this;
    }

    setData(data){

        this.data = data;
    }

    clearErrors(){

        this.errors.clear(); 
    }

    set(field, multifield){

        // Iterate data with keys.

        this.keys = [field];

        // Values that are compared against rules.

        this.values = [];

        if(multifield !== undefined){

            this.keys.push(multifield);
        }

        let data = this.data.get(this.keys[0]);

        if(this.keys.length === 1){

            if(data === undefined){

                data = '';
            }

            this.values.push(data);
        }

        else {

            // When data is not set there is only one empty row.

            if(data === undefined){

                this.values.push('');
            }

            // Otherwise loop trough all rows.

            else {

                data.forEach(v => {

                    let value = v.get(this.keys[1]);

                    if(value === undefined){

                        value = '';
                    }
                
                    this.values.push(value);
                });
            }
        }

        return this;
    }

    // Manipulates errors map.

    setNamedError = (index, type, message) => {

        // Get existing errors.

        const errors = this.getErrors(index);

        // Add new error.

        errors.set(type, message);

        // Singlefield

        if(this.keys.length === 1){

            this.errors.set(this.keys[0], errors);
        }

        // Multifield

        else {

            this.multifield.set(index, errors);
        }
    }

    setError = (rule, message) => {

        this.setNamedError(this.index, rule, message);
    }

    // Get singlefield and set if it doesn't already exists.

    get singlefield(){

        const singlefield = this.errors.get(this.keys[0]);

        if(singlefield === undefined){

            this.errors.set(this.keys[0], new Map());
        }

        return this.errors.get(this.keys[0]);
    }

    // Get multifield and set if it doesn't already exists.

    get multifield(){

        const multifield = this.singlefield.get(this.keys[1]);

        if(multifield === undefined){

            this.singlefield.set(this.keys[1], new Map());
        }

        return this.singlefield.get(this.keys[1]);
    }

    getErrors(index){

        const singlefield = this.errors.get(this.keys[0]);

        // Singlefield

        if(singlefield === undefined){

            return new Map();
        }

        else if(this.keys.length === 1){

            return singlefield;
        }

        // Multifield

        else {

            const multifield = singlefield.get(this.keys[1]);

            if(multifield === undefined){

                return new Map();
            }

            const row = multifield.get(index);

            if(row === undefined){

                return new Map();
            }

            return row;
        }
    }

    // Return first message of every field.

    static parseMessages = (errors) => {

        const messages = [];

        errors.forEach(singlefield => {
       
            const content = singlefield.values().next().value;

            // Singlefield

            if(typeof content !== 'object'){

                messages.push(content);
            }

            // Multifield

            else {

                singlefield.forEach(multifield => {

                    multifield.forEach(row => {
                    
                        messages.push(row.values().next().value);
                    });
                });
            }
        });

        return messages;
    }

    // Returns validation object which contains all errortypes and messages.

    results = () => {

        return this.errors;
    }

    unfilled = field => {

        const value = this.data.get(field);

        return value === undefined || value === '' ? true : false;
    }

    get = field => {

        return this.data.get(field);
    }

    // Validation rules

    isFilled = message => {

        this.values.forEach((value, index) => {

            if(value === '' || value === null){

                this.setNamedError(index, 'isFilled', message);
            }
        });

        return this;
    }

    isInt = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const chars = value.toString().replace(',', '.').split(''); 
                const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

                let isInt = true;

                // If first character is zero string can't contain more than one character.

                if(chars.length >= 2 && chars[0] === "0"){
                
                    isInt = false;
                }

                else {

                    // Check that all characters are numbers.

                    for(let i = 0; i < chars.length; i++){

                        if(allowed.indexOf(chars[i]) === -1){
                        
                            isInt = false; break;
                        }
                    }
                }

                if(isInt === false){

                    this.setNamedError(index, 'isInt', message);
                }
            }
        });

        return this;
    }

    isDecimal = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                // Allow comma as seperator (finnish number delimiter)
                
                const chars = value.toString().replace(',', '.').split(''); 
                const delimiter = '.';
                const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', delimiter];

                let numeric = true;
                let delimiters = 0;

                // Check if array contains anything else than numbers and zero or one delimiter.

                for(let i = 0; i < chars.length; i++){

                    if(chars[i] === delimiter){
                    
                        delimiters++;

                        if(delimiters > 1){

                            // Too many delimiters.

                            numeric = false; break;
                        }
                    }

                    if(allowed.indexOf(chars[i]) === -1){
                    
                        numeric = false; break;
                    }
                }

                // If value is not numeric or it has delimiter in wrong position value is not decimal.

                if(numeric === false ||

                    (chars[0] === delimiter || chars[chars.length - 1] === delimiter) ||
                    (chars.length > 1 && chars[0] === '0' && chars[1] !== delimiter)
                ){

                    this.setNamedError(index, 'isNumeric', message);
                }
            }
        });

        return this;
    }

    isTel = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const regexp = new RegExp('^[0-9+. ()-]+$');

                if(value.match(regexp) === null){

                    this.setNamedError(index, 'isTel', message);
                }
            }
        });

        return this;
    }

    isMobile = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const regexp = new RegExp('^[0-9+. ()-]+$');

                if(value.match(regexp) === null){

                    this.setNamedError(index, 'isMobile', message);
                }
            }
        });

        return this;
    }

    isDate = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(time.set(value, 'dd.mm.yyyy').isValid() === false){

                    this.setNamedError(index, 'date', message);
                }
            }
        });

        return this;
    }

    isDateTime = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(time.set(value, 'dd.mm.yyyy hh:mm').isValid() === false){

                    this.setNamedError(index, 'date', message);
                }
            }
        });

        return this;
    }

    maxSize = (max, message) => {

        this.values.forEach((value, index) => {

            value = value.toString();

            if(value !== null){

                if(value.length > max){

                    this.setNamedError(index, 'maxSize', message);
                }
            }
        });

        return this;
    }

    minSize = (min, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                value = value.toString();

                if(value.length < min){

                    this.setNamedError(index, 'minSize', message);
                }
            }
        });

        return this;
    }

    regexp = (regexp, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(value.match(new RegExp(regexp)) === null){

                    this.setNamedError(index, 'regexp', message);
                }
            }
        });

        return this;
    }

    isNumeric = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(isNumeric(value) === false){
                
                    this.setNamedError(index, 'isNumeric', message);
                }
            }
        });

        return this;
    }

    isGreater = (comparable, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const number = parseFloat(value);

                if(isNaN(number) || number <= comparable){

                    this.setNamedError(index, 'isGreater', message);
                }
            }
        });

        return this;
    }

    isLess = (comparable, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const number = parseFloat(value);

                if(isNaN(number) || number >= comparable){

                    this.setNamedError(index, 'isLess', message);
                }
            }
        });

        return this;
    }

    isZip = message => {

        this.values.forEach((value, index) => {

            if(value !== null){
     
                if(isNumeric(value) === false || value.length !== 5){
                
                    this.setNamedError(index, 'format', message);
                }
            }
        });

        return this;
    }

    // Maxium file size in MB

    maxFilesize = (max, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(value.size === undefined || (value.size / 1000000) > max){

                    this.setNamedError(index, 'maxFileSize', message);
                }
            }
        });

        return this;
    }

    /*

    File type validation:

    types = ['pdf', 'xml']; // Example of types attribute.

    */

    isFile = (types, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                let match = false;

                if(value.name !== undefined){

                    const name = value.name.split('.');
                    const extension = name[name.length - 1].toUpperCase();

                    types.forEach(v => {
             
                        if(v.toUpperCase() === extension){

                            if(extension === 'PDF' && value.type === 'application/pdf'){

                                match = true;
                            }

                            else if(extension === 'XML' && value.type === 'application/xml'){

                                match = true;
                            }

                            else if(extension === 'CSV' && value.type === 'text/plain'){

                                match = true;
                            }

                            else {

                                match = true;
                            }
                        }
                    });
                }

                if(match === false){

                    this.setNamedError(index, 'fileOfType', message);
                }
            }
        });

        return this;
    }

    equalTo = (compare, message) => {

        this.values.forEach((value, index) => {

            if(value !== null){

                if(value !== compare){

                    this.setNamedError(index, 'equalTo', message);
                }
            }
        });

        return this;
    }

    isEmail = message => {

        this.values.forEach((value, index) => {

            if(value !== null){

                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if(regex.test(value) === false){

                    this.setNamedError(index, 'isEmail', message);
                }
            }
        });

        return this;
    }

    isDifferent = (compare, message) => {

        this.values.forEach((value, index) => {
        
            if(value === compare){

                this.setNamedError(index, 'isDifferent', message);
            }
        });

        return this;
    }
}

export default Validator;
