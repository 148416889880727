// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../library/frontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../library/frontend/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../library/frontend/node_modules/css-loader/dist/cjs.js!../../library/frontend/src/styles/roboto.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {

    height: 100%;
    min-height: 100%;
    font-family: Roboto, Arial;
}

img {

    max-width: 100%;
}

* {

    font-size: 14px;
    line-height: 1.5em;
    box-sizing: border-box;
}

@media (min-width: 600px){

    * {

        font-size: 15px;
    }
}

@media (min-width: 1250px){

    * {

        font-size: 16px;
    }
}

#root {

    height: 100%;
    min-height: 100%;
}

h1 {

    font-size: 1.3em;
}

h2 {

    font-size: 1.15em;
}
`, "",{"version":3,"sources":["webpack://./../../ui/styles/app.css"],"names":[],"mappings":"AAEA;;IAEI,YAAY;IACZ,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;;IAEI;;QAEI,eAAe;IACnB;AACJ;;AAEA;;IAEI;;QAEI,eAAe;IACnB;AACJ;;AAEA;;IAEI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":["@import \"library/styles/roboto.css\";\n\nhtml, body {\n\n    height: 100%;\n    min-height: 100%;\n    font-family: Roboto, Arial;\n}\n\nimg {\n\n    max-width: 100%;\n}\n\n* {\n\n    font-size: 14px;\n    line-height: 1.5em;\n    box-sizing: border-box;\n}\n\n@media (min-width: 600px){\n\n    * {\n\n        font-size: 15px;\n    }\n}\n\n@media (min-width: 1250px){\n\n    * {\n\n        font-size: 16px;\n    }\n}\n\n#root {\n\n    height: 100%;\n    min-height: 100%;\n}\n\nh1 {\n\n    font-size: 1.3em;\n}\n\nh2 {\n\n    font-size: 1.15em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
