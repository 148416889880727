export default function download(url){

    const link = document.createElement('a');

    link.href = url;
    link.download = '';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}
